
import { defineComponent, ref, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import pengguna, {
  getPengguna,
  IPengguna,
  deletePengguna,
  resetPasswordPengguna,
} from "./servicePengguna";
import ModalEdit from "@/views/master/pengguna/modalEdit.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  name: "UsersPage",
  components: {
    Datatable,
    ModalEdit,
  },

  setup() {
    const tableData = ref<Array<IPengguna>>(pengguna);
    const modalEdit = ref();
    const tableKey = ref(0);
    const total_data = ref(0);
    const itemsPerPage = ref(10);
    const is_loading = ref(true);
    const currentPage = ref(1);
    const search = ref<string>("");
    const tableHeader = ref([
      {
        name: "Full Name",
        key: "full_name",
        sortable: true,
      },
      {
        name: "Username",
        key: "username",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Status",
        key: "disabled",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);
    const isShowModal = ref(false);
    const keyModal = ref(1);
    const dataUser = ref<IPengguna>();

    const fetchData = async () => {
      is_loading.value = true;
      const res = await getPengguna();
      const val = res.filter(
        (el) =>
          el.full_name.toLowerCase().includes(search.value.toLowerCase()) ||
          el.username.toLowerCase().includes(search.value.toLowerCase()) ||
          el.email.toLowerCase().includes(search.value.toLowerCase())
      );
      tableData.value = val;
      is_loading.value = false;
      tableKey.value += 1;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("User", ["Users"]);
      fetchData();
      MenuComponent.reinitialization();
    });

    const currentChange = (current: number) => {
      currentPage.value = current;
      fetchData();
    };

    const deleteUser = (id) => {
      Swal.fire({
        title: "Apakah anda yakin ingin mengahapus user tersebut?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const loadout = {};
          const res = await deletePengguna(loadout, id);
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              toast: true,
              title: "Berhasil! Pengguna berhasil dihapus",
              showConfirmButton: false,
              timer: 3000,
            });
            fetchData();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Terjadi kesalahan saat menghapus pengguna",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    };
    const resetPassword = (id) => {
      Swal.fire({
        title: `Apakah anda yakin ingin melakukan reset password pada user tersebut?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const loadout = {
            user_id: id,
          };
          const res = await resetPasswordPengguna(loadout);
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              toast: true,
              title: "Berhasil! Password telah di reset",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Terjadi kesalahan saat mereset password",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    };

    const itemsPerPageChange = (currentItemsPerPage: number) => {
      itemsPerPage.value = currentItemsPerPage;
      fetchData();
    };

    const closeModal = () => {
      isShowModal.value = false;
    };

    const showModalEdit = (data) => {
      modalEdit.value?.showModal(data);
    };
    return {
      tableData,
      total_data,
      isShowModal,
      keyModal,
      dataUser,
      tableHeader,
      is_loading,
      currentPage,
      search,
      itemsPerPage,
      currentChange,
      itemsPerPageChange,
      tableKey,
      closeModal,
      showModalEdit,
      modalEdit,
      deleteUser,
      resetPassword,
      fetchData,
    };
  },
});
